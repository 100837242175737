/* Copyright (C) 2023 Christian Miley - All Rights Reserved */


/*Tiny Mobile (not a bootstrap breakpoint)*/
@media (width < 400px) {
    .Header 
    {
        font-size: 5em;
    }
    .Sub-header-signUp
    {
        flex-direction: column;
    }
}

/*X-small and Small bootstrap breakpoints*/
@media (400px <= width < 768px) {
    .Header 
    {
        font-size: 6em;
    }
    .Sub-header-signUp
    {
        flex-direction: row;
    }
}

/*Medium to Large bootstrap breakpoints*/
@media (768px <= width < 1200px) {
    .Header 
    {
        font-size: 6em;
    }
    .Sub-header-signUp
    {
        flex-direction: row;
    }
}

/*Extra large and greater breakpoints*/
@media (width >= 1200px) {
    .Header 
    {
        font-size: 6em;
    }
    .Sub-header-signUp
    {
        flex-direction: row;
    }
}

.HomeBackground
{
    height: 100%;
    width: 100%;
    background: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: var(--primary-bgd-color);
    z-index: -1;
}

.Header
{
    color: white;
    text-align: center;
    z-index: 1;
    margin-top: 0px;
    margin-bottom: -50px;
}

.Sub-header
{
    color: white;
    /*font-size: 30px;*/
    text-align: center;
    margin-bottom: 10px;
}

.Sub-header-signIn
{
    width: 42%;
    min-width: 260px;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.705);
    margin: 0 auto;
    margin-bottom: 40px;
    text-align: center;
    font-size: 18px;
}

.Sub-header-signUp
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.WelcomeBack
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: white;
    margin: 1em;
}

.Entry
{
    font-size: 20px;
    margin: 10px;
}

.SignInFormField
{
    margin: 2em 0em 0em 0em;
}

.SignInField
{
    /*border-radius: 4px;*/
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid gray;
    background-color: transparent; 
    /*color: white;*/
    color: black;
}

.SignInField:focus
{
    background-color: transparent;
    /*color: white;*/
    color: black;
    border-color: white;
    border-radius: 6px;
}

.SignInField::placeholder
{
    /*color: white;*/
    color: gray;
    opacity: 0.8;
}

.SignIn
{
    margin-top: 2.75em;
    color: black;
    background-color: var(--secondary-bgd-color);
    border: 1.5px solid transparent;
    width: 100%;
    border-radius: 4px;
}

.SignIn:hover
{
    /*background-color: var(--secondary-bgd-color);
    /*text-decoration: underline;
    /*font-style: italic;*/
    border: 1.5px solid darkblue;
    color: white;
}

.SignUpText
{
    margin: 1em;
    color: black;
}

.SignUpToday
{
    margin: 1em;
    color: black;
    text-decoration: underline;
}

.SignUpToday:hover
{
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    /*color: black !important;*/
}

.ContinueAsGuest
{
    margin: 20px;
    font-size: 16px;
    color: rgb(182, 182, 182);
    text-align: center;
}

a:hover
{
    text-decoration: none;
}

.Button
{
    background: var(--button-color);
    font-size: 16px;
    margin-left: 30px;
}

.Button:hover
{
    background: var(--button-hover-color);
}

.HomeOptions
{
    display: flex;
    width: 60%;
    margin: 0 auto;
    align-items: baseline;
    text-align: center;
}

.Option
{
    text-decoration: none;
    color: white;/*rgba(255, 255, 255, 0.5);*/
    /*background-color: var(--primary-bgd-color);*/
    font-size: 24px;
    border: none;
}

.Option:hover
{
    /*color: rgba(255, 255, 255, 1.0);*/
    text-decoration: underline;
    /*background-color: var(--primary-bgd-color);*/
    border: none;
}

/*.Option:active
{
    color: rgba(255, 255, 255, 1.0);
    background-color: var(--primary-bgd-color);
    border: none;
}*/