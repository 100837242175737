/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

.Terms
{
    font-size: 0.875em;
    text-align: justify;
}

.SubTerms
{
    font-size: 0.875em;
    text-align: justify;
    margin: 0.5em 2vw;
}

.SubTermsTitle
{
    text-align: left;
    margin: 0.5em 2vw;
}