/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

.PhoneVerification
{
    max-width: 14em;
    text-align: center;
    justify-content: center;
}

.PhoneVerificationContainer
{
    display: flex; /*block;
    margin-left: auto;
    margin-right: auto;*/
    justify-content: center;
    padding-top: 6em;
}

.submitButton
{
    margin-top: 1em;
}