/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

.LoadingCircle {
    margin: auto !important;
    border: 1px solid white !important;
    border-top: 1px solid grey !important;
    border-radius: 50% !important;
    box-sizing: border-box !important;
    width: 10% !important;
    height: 0 !important;
    padding-top: 10% !important;
    animation: spinner 1s linear infinite !important;
}

@keyframes spinner {
    0% {transform: rotate(0deg); opacity: 5%}
    25% {transform: rotate(60deg);}
    50% {transform: rotate(160deg); opacity: 100%}
    75% {transform: rotate(300deg);}
    100% {transform: rotate(360deg); opacity: 5%}
}