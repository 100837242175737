/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

.App
{
    font-family: sans-serif;
}

.App-header
{
    text-align: center;
    font-size: 40px;
    margin: 10px;
}

.Background
{
    position:fixed;
    background:var(--primary-bgd-color);
    width: 100%;
    height: 100%;
    min-height: 700px;
    top: 0;
    left: 0;
    z-index: -1;
}

.Background2
{
    position:fixed;
    background:var(--secondary-bgd-color);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
}

.Header
{
    color: white;
    font-size: 180px;
    text-align: center;
    z-index: 1;
    margin-top: 40px;
    margin-bottom: 40px;
}

.Sub-header
{
    color: white;
    font-size: 30px;
    text-align: center;
    margin: 50px;
}

.Sub-header-signIn
{
    width: 42%;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.705);
    margin: 0 auto;
    margin-bottom: 80px;
    text-align: center;
    font-size: 18px;
}

.Sub-header-signUp
{
    display: flex;
    width: 40%;
    margin: 0 auto;
    display: flex;
    align-items: baseline;
    text-align: center;
}

.Entry
{
    font-size: 20px;
    margin: 10px;
}

.SignUpText
{
    font-size: 16px;
    color: rgb(182, 182, 182);
    margin-left: 42px;
}

.ContinueAsGuest
{
    margin: 20px;
    font-size: 16px;
    color: rgb(182, 182, 182);
    text-align: center;
}

a:hover
{
    text-decoration: none;
}

.Button
{
    background: var(--button-color);
    font-size: 16px;
    margin-left: 30px;
}

.Button:hover
{
    background: var(--button-hover-color);
}