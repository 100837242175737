/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

.CampaignContainer
{
   max-width: 900px !important;
}

.MeasureInfo
{
   text-align: center;
   font-weight: 300; 
}

.Step
{
   text-align: center;
   margin-bottom: 1em;
}

.Address
{
   text-align: center;
   border: 1px solid black;
   border-radius: 20px;
   padding: 2em 2em 1em 2em;
   max-width: 340px;
}

.Section
{
   text-align: center;
   padding-top: 2em;
   padding-bottom: 2em;
   border-top: solid 1px black;
   display: flex;
   flex-direction: column;
   align-items: center;
}

/* For some reason below are getting overriden*/
.GetPacketButton
{
   margin: 1em 0em 0em 0em !important;
   /*background-color: aquamarine !important;
   border-color: gray !important;
   color: black !important;*/
}

/*.GetPacketButton:active
{
   background-color: purple !important;
   color: white !important;
}

.GetPacketButton:hover
{
   background-color: green !important;
   color: white !important;
}*/

.PacketFormSelect
{
   min-width: 200px;
   padding-top: 1em;
}

.CampaignInstructionsBox
{
   background-color: var(--secondary-bgd-color);
   padding: 1em;
   width: 100%;
   border-radius: 4px;
}

.ProponentControlsBox
{
   border: 1px solid black;
   padding: 1em;
   margin: 2em 0em 2em 0em;
   border-radius: 3px;
}

.ProponentControls
{
   display: flex;
   justify-content: center;
   align-items: baseline;
}

.EditMeasureLink
{
   text-align: center;
   padding: 0.38em;
   border: 1px solid;
   border-radius: 8px;
   background-color: aquamarine;
   border-color: gray;
   color: black;
   text-decoration: none;
}

.EditMeasureLink:hover
{
   background-color: green;
   color: white;
}