/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

:root
{
    --primary-bgd-color: rgb(218, 81, 71);/*rgba(2, 91, 255, 0.87);*/
    --secondary-bgd-color: #DFF2FF;/*#D9F6FF;/*rgb(189,255,253);*/
    --button-color: rgb(99,71,77);
    --button-hover-color: rgb(197, 0, 0);
    --navbar-color: rgb(245, 125, 117);
    --sign-button-color: rgb(80, 62, 243);
}

.bg-primary
{
    background-color: rgb(255,141,133) !important;
}