@media (width < 400px)
{
    .SignContainer
    {
        flex-direction: column;
        align-items: center !important;
    }
    .SignCard
    {
        width: 100% !important;
    }
}

.SignContainer
{
    margin-top: 2em;
    display: flex;
    justify-content: center;
}

.SignCard
{
    width: 50%;
    text-align: center;
    margin: 0.25em 0.5em;
    max-width: 18em;
}

.SignOptionButton
{
    border-radius: 7px;
    padding: 0.5em;
    background-color: blue !important;
    border: 0px; 
    color: white;
    /*margin: 0em 0.5em 1em;*/
    text-align: justify;
    text-decoration: none;
    /*min-width: 100% !important;*/
}

.SignOptionButton:hover
{
    background-color: var(--sign-button-color) !important;
}