/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/*Mobile*/

/*@media (width < 420px) {
    .PropositionInfoContainer
    {
        margin: 0em 0em 0em -1em !important;
    }
    /*.MeasureContainer
    {
        margin: 2em 0em !important;
        padding: 0em !important;
    }
}*/

/*X-small and Small bootstrap breakpoints*/
@media (width < 768px) {
    .PropBox
    {
        text-align: left;
        margin: 1em 0em 0em;
        width: 100%;
    }
    /*Not great but passable*/
    .Tooltip
    {
        margin-top: -7.5em;
        left: 25vw;
    }
    /*.PropInfoTable
    {
        flex-direction:
    }*/
    .InformationSection
    {
        flex-direction: column;
    }
    .MeasureSidebar
    {
        margin-top: 0em !important;
    }
}

/*Medium to Large bootstrap breakpoints*/
@media (768px <= width < 1200px) {
    .Tooltip
    {
        margin-top: -4em;
        margin-left: 1em;
    }
    .MeasureSidebar
    {
        position: sticky;
        top: 0.5em;
        align-self: flex-start;
        margin-left: 0em !important;
        max-width: 16em;
    }
}

/*Extra large and greater breakpoints*/
@media (width >= 1200px) {
    .Tooltip{
        margin-top: -4em;
        margin-left: 1em;
    }
    .MeasureSidebar
    {
        position: sticky;
        top: 0.5em;
        align-self: flex-start;
        margin-left: 0em !important;
        max-width: 18em;
    }
}

/*.MeasureSidebar
{
    position: sticky;
}*/

.MeasureInfoAccordionItem
{
    /*border-width: 0px 0px 1px !important;
    border-style: solid;
    border-color: black;*/
    white-space: pre-wrap;
}

.MeasureInfoAccordionHeader
{
    font-weight: bold;
    /*font-size: 1.2em;*/
}

/* This will override the bootstrap accordion property... */
/* https://smartdevpreneur.com/how-to-change-bootstrap-accordion-background-color/#Bootstrap_Accordion_Active_Background_Color_and_Text_Color */
.accordion {
    --bs-accordion-active-bg: none !important;
}

.NavigationBar
{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.MeasurePageBackground
{
    position:fixed;
    background:var(--secondary-bgd-color);
    width: 100%;
    height: 100%;
    min-height: 700px;
    top: 0;
    left: 0;
    z-index: -1;
}

.MeasureContainer
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2em 0em 0em 0em;
    max-width: 100%;
}

.PropositionInfoContainer 
{
    /*background: #F1F1F1;*/
    max-width: 1000px;
    /*padding: 4em;*/
    padding-top: 2em;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    /* This is now necessary to center it at small screen sizes, 
     but I'm not sure where the extra 1em of margin is coming from on the left.
    */
    margin: 0em 1em 0em 0em;
}

.SignatureBox
{
    background: #F1F1F1;
    padding: 10px;
    padding-bottom: 0px;
}

.PropBox
{
    /*border-color: grey;
    border: 1px;*/
    border-radius: 6px;
    background: white;
    padding: 0.5em 0.5em 0.2em;
    margin: 10px;
}

.Tooltip
{
    visibility: hidden;
    transition: opacity 0.3s;
    color: white;
    padding: 0.5em;
    position: absolute;
    border-radius: 6px;
    opacity: 0;
    width: 40vw;
    min-width: 300px;
}

.HasTooltip:hover
    .Tooltip
    {
        visibility: visible;
        opacity: 1;
        background: #555;
    }

.Actions
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MinorActions
{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.MainAction
{
    margin: 1em 2em 0em;
}

.ShareButtons
{
    margin: 2em;
}

.Addendum
{
    margin: 2em;
    text-align: left;
}

.LegalDisclaimer
{
    font-weight: 300;
    color: gray;
}

.FollowButton
{
    background-color: var(--primary-bgd-color) !important;
    border: 0px !important;
    border-radius: 8px !important;
    padding: 0.35em !important;
    color: white !important;
    text-decoration: none !important;
}

.FollowButton:hover
{
    background-color: maroon !important;
}

.SignButton
{
    background-color: var(--sign-button-color);
    max-width: 1000px;
    width: 75vw;
    padding: 0.25em !important;
    font-size: 1.25em !important;
}