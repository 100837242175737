/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

@media (width < 500px) {
    .ServicesButtons
    {
       flex-direction: column;
    }
    .IconText::before{
        content: '' !important;
    }
}

/*Mobile*/
/*X-small and Small bootstrap breakpoints */
@media (width < 768px) {
    .PropCard
    {
        padding: 1em;
        color: black;
        flex-direction: column;
        width: 90%;
        text-align: center;
        margin: auto;
    }
    .SignatureBar
    {
        transform: rotate(90deg);
        margin-top: -4.5em;
        margin-bottom: -6em;
    }
    .SeeMoreButton
    {
        text-align: center;
        z-index: 100;
    }
    .HideButton
    {
        z-index: 100;
    }
    .OrgButton
    {
        z-index: 100;
    }
    .MobileSignBox
    {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        z-index: 420;
        background: blue;
        text-align: center;
        padding: 1em;
    }
    .MobileSignLink
    {
        color: white;
        z-index: 421;
    }
    .MobileSignLink:hover
    {
        color: white;
        text-decoration: underline;
    }
    .MobileSignSpan
    {
        color: white;
        z-index: 421;
    }
    .Feed
    {
        /* the quick residency change bar is not present on small, so lose an em of margin */
        margin-top: 3em !important;
    }
}

/*Medium to Large bootstrap breakpoints*/
@media (768px <= width < 1200px) {
    .PropCard
    {
        padding: 1em;
        color: black;
        flex-direction: column;
        width: 90%;
        text-align: center;
        margin: auto;
    }
    .CardText
    {
        min-width: 90%;
    }
    .SignatureBar
    {
        margin-left: auto;
        margin-right: 0;
    }
}

/*Extra large and greater breakpoints*/
@media (width >= 1200px) {
    .PropCard
    {
        padding: 1em;
        color: black;
        flex-direction: column;
        width: 90%;
        text-align: center;
        margin: auto;
    }
    .CardText
    {
        min-width: 90%;
    }
    .SignatureBar
    {
        margin-left: auto;
        margin-right: 0;
    }
}

.ModernButton
{
    padding: 0.4em;
    color: black;
    background: transparent;
    border-radius: 10px;
    border: 0px;
    margin: 0.25em;
    text-decoration: none;
    /* below will make these buttons split up the space entirely */
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.ModernButton:hover
{
    background: rgba(0, 0, 0, 0.05) !important;
}

.ModernButton:focus
{
    background: none;
}

.ServicesButtons
{
    display: flex; 
    margin: 0em; 
    justify-content: space-evenly; 
    align-items: center;
    flex-direction: row;
}
    
.IconText::before
{
    content: attr(text);
    margin-left: 0.25em;
}

/*.CardText
{
    display: flex;
    flex-direction: column;
    align-items: center;
}*/

.ResidencyForm
{
    padding: 2em;
    /*border: 0.5px;
    border-color: black;
    border-style: solid;*/
}

.UserFeedPageContainer 
{
    width: 100%;
}

.FeedBackground 
{
    height: 100%;
    width: 100%;
    background: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: var(--secondary-bgd-color);;
    z-index: -1;
}

.Feed 
{
    margin-top: 4em;
    background: var(--secondary-bgd-color);
}

.PreFooter
{
    margin: 0em;
    display: flex;
    justify-content: space-between;
}

.NavigationBar 
{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.NavBarLinks 
{
    color: lightgrey;
}

.NavButton
{
    color: lightgrey;
    background: var(--navbar-color);
    border: none;
}

.NavButton:hover
{
    /* background: rgb(121, 121, 255); */
    background: var(--navbar-color);
    color: white;
    border: none;
}

.HideButton
{
    background: rgb(201, 48, 48);
    color: white;
    border-width: thin;
    border-style: solid;
    border-color: rgb(201, 48, 48);
    padding: 0.4em;
    min-width: 4em;
}

.HideButton:hover{
    color: white;
    background: red;
    border-color: red;
    padding: 0.4em;
    min-width: 4em;
}

.SeeMoreButton
{
    background: rgb(58, 199, 2);
    color: white;
    border-width: thin;
    border-style: solid;
    border-color: rgb(58, 199, 2);
    padding: 0.4em;
    margin-left: 0.6em;
    text-decoration: none;
}

.SeeMoreButton:hover{
    color: white;
    background: green;
    border-color: green;
    padding: 0.4em;
}

.OrgButton
{
    background: var(--sign-button-color);
    color: white;
    border-width: 1px;
    border-style: solid;
    border-color: var(--sign-button-color);
    padding: 0.4em;
    min-width: 4em;
    border-radius: 0px 7px 7px 0px;
    text-decoration: none;
}

.OrgButton:hover{
    color: white;
    background: blue;
    border-color: blue;
    padding: 0.4em;
    min-width: 4em;
}

.CreatePropositionButton 
{
    background: var(--button-color);
    color: white;
    padding: 10px;
    width: 100%;
}

.CreatePropositionButton:hover{
    /* background: rgb(218, 0, 0); */
    background: var(--button-hover-color)
}

.Summary{
    line-height: 1.5em;
    max-height: 4.5em;
    overflow: hidden;
    /*white-space: preserve nowrap;
    text-overflow: ellipsis;*/
    transition: max-height 1s;
    cursor: pointer;
    text-align: left;
    position: relative;
}

.Summary:focus{
    /*white-space: normal;*/
    max-height: 100em; 
    cursor: default;
}

/* https://css-tricks.com/line-clampin/ */
.Summary::after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
    height: 1.2em;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    transition: background-image 5s;
}

.Summary:focus::after{
background-image: none;
}