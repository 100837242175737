/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/**
Found way to expand a webpage past its parent container from
https://css-tricks.com/full-width-containers-limited-width-parents/ 
**/

/*X-small and Small bootstrap breakpoints*/
@media (width < 768px) {
    .Text
    {
        text-align: left;
    }
    .AboutHeaderContainer
    {
        flex-direction: column;
    }
    .Sidebar
    {
        /*top: 0em;*/
        width: 100%;
        height: 4em !important;
        padding: 1em 1em 0em !important;
        margin-top: 3.5em;
        line-height: 1.2em !important;
    }
    .MainAbout
    {
        margin: 7em 0.5em 0em !important;
        padding: 0em !important;
    }
    .AboutNav
    {
        padding-bottom: 10em;
    }
}

/*Medium to Large bootstrap breakpoints*/
@media (768px <= width < 1200px) {
    .Text
    {
        text-align: justify;
    }
}

/*Extra large and greater breakpoints*/
@media (width >= 1200px) {
    .Text
    {
        text-align: justify;
    }
}

.AboutHeaderContainer
{
    /*width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;*/
    
    /*background: var(--primary-bgd-color);
    z-index: 100;
    text-align: center;*/
    display: flex;
    flex-direction: row;
    height: 100%;
}

/*.AboutHeader
{
    color: white;
    font-size: 6em;
    text-decoration: none;
}

.AboutHeader:hover
{
    text-decoration: underline;
}

.AboutSubheader
{
    /*padding: 0.5em;
    padding-top: 0.25em;
    color: white;
    text-align: center;
}*/

.MainAbout
{
    margin-top: 6em;
    padding: 0em 3em;
    margin-left: 12em;
    /*display: flex;
    align-items: flex-start;*/
}

.Sidebar
{
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 7em 3em 0em;
    height: 100%;
    position: fixed;
    /*justify-content: left;
    align-content: left;*/
    background: var(--secondary-bgd-color);
    font-family: "Open Sans", Helvetica, sans-serif;
    color: black;
    /*padding: 60px 60px 300px;*/
    line-height: 5;
    overflow: auto;
}

.AboutNav
{
    color: black;
    /*position: relative;
    margin: 0em 2em;*/
}

.AboutNav.active
{
    text-decoration: underline;
}

.AboutNav:hover
{
    color: white;
}



.Text
{
    padding: 2em 1em;
    margin: 0px;
    font-size: 20px;
    color: black;
    line-height: 2.4;
}

.AboutParagraph
{
    margin-bottom: 60px;
}

