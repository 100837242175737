/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

.AccordionValidate
{
    color:'red';
    font-size:'1.25em';
    margin-left:'1em';
    padding:'1px 3px';
    border:'1px';
    border-width:'2px';
    border-style:'dashed solid';
}

.AddListEntryButton
{
    text-align: center;
    background-color: skyblue;
    margin: 1em 20%;
    border: 0px;
}

.RemoveListEntryButton
{
    background-color: skyblue;
    border: 0px;
}

.NextButton
{
    margin-top: 0.5em;
    background-color: skyblue;
    border: 0px;
}

.RefreshButton
{
    font-size: 2em;
    background-color: skyblue;
    border: 0px;
    flex-grow: 2;
    padding: 1em;
}

.PageToggleButton
{
    border-radius: 0px;
    background-color: skyblue;
    border-color: lavender;
}

.ControlsAccordionBody
{
    display: flex;
    justify-content: center;
    padding: 0.5em 0em;
    border-radius: 6px 6px 0px 0px;
}

.ValidAccordion
{
    color: green;
    font-size: 1.25em;
    margin-left: 1em; 
    padding: 1px 3px; 
    border: 1px; 
    border-width: 2px; 
    border-style: dashed solid;
}

.InvalidAccordion
{
    color: red;
    font-size: 1.25em;
    margin-left: 1em;
    padding: 1px 3px;
    border: 1px; 
    border-width: 2px; 
    border-style: dashed solid;
}