/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/*Mobile*/
/*X-small and Small bootstrap breakpoints*/
@media (width < 768px) {
    .UserPage
    {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Left
    {
        /*width: 90%;*/
        /*max-width: 18em;*/
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
    }
    .UserInfoContainer
    {
        /*display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;*/
        border-right: 1px solid lightblue !important;
    }
    .Right
    {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .SignaturesGrid
    {
        flex-direction: column;
        align-items: center;
    }
    .ImpactStats
    {
        flex-direction: column;
    }
}

/*Medium to Large bootstrap breakpoints*/
@media (768px <= width < 1200px) {
    .UserPage
    {
        align-items: flex-start;
    }
    .Left
    {
        width: 30vw;
        /*position: relative;
        z-index: 101;*/
    }
    .Right
    {
        width: 70vw;
        /*position: relative;
        z-index: 102;*/
        /*margin-bottom: 1em;
        margin-top: -2em;*/
    }
    .SignaturesGrid
    {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

/*Extra large and greater breakpoints*/
@media (width >= 1200px) {
    .UserPage
    {
        align-items: flex-start;
    }
    .Left
    {
        width: 30vw;
        /*position: relative;
        z-index: 101;*/
    }
    .Right
    {
        width: 70vw;
        /*position: relative;
        z-index: 102;*/
        /*margin-bottom: 1em;
        margin-top: -2em;*/
    }
    .SignaturesGrid
    {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.CommentBox:focus
{
    outline-width: 0;
}

.ReactionsDropdown:hover
{
    color: black;
}

.ReactionsDropdown:focus
{
    color: black;
}

.ReactionsDropdown::after{
    content: none !important;
}

.UserBackground
{
    position:fixed;
    background:var(--secondary-bgd-color);
    width: 100%;
    height: 100%;
    min-height: 700px;
    top: 0;
    left: 0;
    z-index: -1;
}

.UserInfoContainer 
{
    /*background: transparent;/*#F1F1F1;*/
    /*padding: 2em;
    /*margin-top: -1.9em;*/
    border: 1px solid lightblue;
    border-radius: 7px 0px 0px 7px;
    border-right: 0;
}

.UserBox
{
    border-color: grey;
    border: 1px;
    background: white;
    border-radius: 6px;
    padding: 10px;
    margin: 10px;
}

.UserPage
{
	display: flex;
    align-items: flex-start;
    margin-top: 2em;
}

.SignaturesGrid
{
    display: flex;
    justify-content: center;
}

.LoadButton
{
    margin-left: 35vw;
    margin-bottom: 10em;
}

.ImpactStats
{
    display: flex; 
    justify-content: space-evenly;
    margin-bottom: 0em;
}