/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/*Smaller Mobile?*/
@media (330px < width < 440px) {
	.CountryCode
	{
		min-width: 5em !important;
		max-width: 30% !important;
	}
	.Phone
	{
		max-width: 70% !important;
	}
}

@media (width < 480px) {
	.SignUpRowItemFull
	{
		width: 100%;
	}

	.ProponentSignUpContainer
	{
		padding: 0em 0em !important;
	}
}

/*Mobile*/
/*X-small and Small bootstrap breakpoints*/
@media (660px < width < 768px) {
	.CountryCode
	{
		min-width: 5em !important;
		max-width: 30% !important;
	}
	.Phone
	{
		max-width: 70% !important;
	}
}

/*Medium to Large bootstrap breakpoints*/
@media (768px <= width < 1200px) {
	.CountryCode
	{
		min-width: 5em !important;
		max-width: 30% !important;
	}
	.Phone
	{
		max-width: 70% !important;
	}
}

/*Extra large and greater breakpoints*/
@media (width >= 1200px) {
	.CountryCode
	{
		min-width: 5em !important;
		max-width: 30% !important;
	}
	.Phone
	{
		max-width: 70% !important;
	}
}

.SignUpContainer
{
	text-align: left;
	max-width: 600px !important;
	border-radius: 10px !important;
	/*width: 50vw;*/
}

.Guidelines
{
	/*background-color: white;/*#F1F1F1;*/
	text-align: justify;
	margin-top: 1em;
	padding: 1em;
}

.SignUpForm
{
	/*background: #F1F1F1;*/
	padding: 0em 2em 2em 2em;
}

.SignUpHeader
{
    color: white;
    font-size: 2em;
    text-align: center;
    z-index: 1;
	margin: 2vw;
}

.CountryCode
{
	min-width: 5em;
	max-width: 30%;
}
.Phone
{
	max-width: 70%;
}

.ProponentSignUpContainer
{
	text-align: center;
	max-width: 900px !important;
	padding: 0em 2em;
}

.SignUpRow
{
	margin-left: 2px;
	margin-top: 1em;
	text-align: left;
	min-width: 180px;
}

.SignUpRowItemFull
{
	margin-right: 1em;
}

.SignUpRowItem
{
	padding-top: 1em;
	margin-right: 1em;
	text-align: left;
}

.ErrorMessage
{
	font-size: 1em;
	color: red;
	/*border: 1px solid;
	border-color: black;*/
	padding: 0.25em;
}

.SignUpSubmit
{
	margin-top: 1em;
	text-align: center;
}

.Required
{
	color: red;
}

/* This is for actionhandler below */

.ActionHandlerLink
{
	color: black;
	text-decoration: underline;
}

.ActionHandlerLink:hover
{
	text-decoration: none;
}

/* This is for unverified email and others */

.NotBlueButton
{
	background-color: darkred !important;
	border: 0px !important;
}

.NotBlueButton:hover
{
	/*font-style: italic;*/
	text-decoration: underline;
}

