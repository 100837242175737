/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/*Mobile*/
/*X-small and Small bootstrap breakpoints*/
@media (width < 768px) {
    .MailedOnDate
    {
        flex-direction: column;
    }
}

.MailedOnDate
{
    display: flex; 
    margin: 1em 0em; 
    align-items: baseline;
}