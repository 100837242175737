/* Copyright (C) 2023 Christian Miley - All Rights Reserved */

/*Mobile*/
/*X-small and Small bootstrap breakpoints*/
@media (width < 768px) {
    .Toggle
    {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .NavBrand
    {
        z-index: -100 !important;
        color: var(--primary-bgd-color);
    }
    /*.SearchBox
    {
        margin: 0.25em;
        margin-left: 0.6em;
        margin-right: 0em;
        width: 10em;
    }
    .SearchButton
    {
        margin-left: 0.25em;
    }*/
    .SignOut
    {
        width: 6em;
        margin: 0.25em;
    }
    .MobileBackButton
    {
        position: fixed;
        width: 50%;
        /*left: 0;
        /*bottom: 0;*/
        top: 0;
        z-index: 420;
        /*background: var(--primary-bgd-color);*/
        text-align: center;
        padding: 0.5em;
    }
    .NavigationBar
    {
        z-index: 1;
    }
    .CollapsedHeader
    {
        color: white;
        text-align: center;
        margin: 0;
        padding: 0;
    }
    .CollapsedHeader::after{
        content: attr(text);
        margin: 0;
        padding: 0;
    }
    .QuickChangePersonContextBar
    {
        display: none !important;
    }
}

/*Medium to Large bootstrap breakpoints*/
@media (768px <= width < 1200px) {
    .MobileBackButton
    {
        width: 0%;
        z-index: -1;
    }
    .ClearMeasuresButton
    {
        display: none;
    }
}

/*Extra large and greater breakpoints*/
@media (width >= 1200px) {
    .MobileBackButton
    {
        width: 0%;
        z-index: -1;
    }
    .ClearMeasuresButton
    {
        display: none;
    }
}

.UserFeedPageContainer 
{
    width: 100%;
}

/*.SearchButton
{
    margin-left: 10px;
}*/

.NavigationBar 
{
    width: 100%;
    position: fixed;
    top: 0;
    /*left: 0;*/
    background-color: var(--primary-bgd-color);
    z-index: 420;
}

.NavBarLinks 
{
    color: lightgrey;
    z-index: 420;
    background-color: var(--primary-bgd-color);
}

.CollapsedHeader
{
    z-index: -1;
}

.NavButton
{
    color: lightgrey;
    background: transparent;
    border: none;
    margin: 0;
    margin-right: -0.8em;
}

.NavButton:hover
{
    /* background: rgb(121, 121, 255); */
    background: transparent;
    color: white;
    border: none;
}


.NavLink
{
    color: lightgrey;
    background: transparent;
    border: none;
    /*margin-left: 0.8em;*/
    text-decoration: none;
}

.NavLink:hover
{
    /* background: rgb(121, 121, 255); */
    background: transparent;
    color: white;
    border: none;
}

.CreatePropositionButton 
{
    background: var(--button-color);
    color: white;
    padding: 10px;
    width: 100%;
}

.CreatePropositionButton:hover{
    /* background: rgb(218, 0, 0); */
    background: var(--button-hover-color)
}

.NavDropdown
{
    color: lightgrey;
    background: transparent;
}

.NavDropdownInside
{
    display: flex;
    flex-direction: column;
}

.NavDropdownItem
{
    color: black;
    text-align: center;
}

.NavDropdownItem:hover
{
    color: var(--primary-bgd-color);
}